import React from "react";
import { Exercises } from "../util/Exercises";
import DataTable from "react-data-table-component";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import ExerciseProgressChart from "./ExerciseProgressChart";
const columns = [
  {
    name: "Set",
    maxWidth: "30px",
    selector: (row) => row.set_no,
  },
  {
    name: "Reps",
    maxWidth: "30px",
    selector: (row) => row.reps,
  },
  {
    name: "Weight",
    maxWidth: "30px",
    selector: (row) => row.weight,
  },
];
const WorkoutExerciseComponent = (props) => {
  console.log(props);
  const contentStyle = { width: "930px" };
  const overlayStyle = {};

  return (
    <div>
      <b>
        {Exercises[props.exercise_id]}
        <Popup
          trigger={<button> Check Progression</button>}
          position="right center"
          modal
          {...{ contentStyle, overlayStyle }}
        >
          <ExerciseProgressChart
            exerciseId={props.exercise_id}
            chartTitle={Exercises[props.exercise_id]}
            isRelativeToBW={false}
          />
        </Popup>
      </b>
      <DataTable columns={columns} data={props.sets} />
    </div>
  );
};

export default WorkoutExerciseComponent;
