export const MaleRanges = {
    "1": {
        //Barbell bench press
        "Noob": [0.0, 0.3],
        "Beginner": [0.3, 1],
        "Intermediate": [1, 1.5],
        "Advanced": [1.5, 2],
        "Elite": [2,2.25],
        "Freak": [2.25,3.25]
    },
    "8": {
        //Squat
        "Noob": [0.0, 0.3],
        "Beginner": [0.3, 1.25],
        "Intermediate": [1.25, 1.75],
        "Advanced": [1.75, 2.5],
        "Elite": [2.5,3],
        "Freak": [3,4]
    },
    "9": {
        //Deadlift
        "Noob": [0.0, 0.5],
        "Beginner": [0.5, 1.5],
        "Intermediate": [1.5, 2.25],
        "Advanced": [2.25,3],
        "Elite": [3,3.5],
        "Freak": [3.5,4.5]
    }
};