import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SaikyoApi = axios.create({
  baseURL: process.env.REACT_APP_SAIKYO_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  xsrfHeaderName: "X-XSRF-TOKEN",
  withCredentials: true,
});

if (localStorage.getItem("apiToken") != null){
  SaikyoApi.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("apiToken")}`;
}

SaikyoApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // Redirect to the login page
      window.location = '/login';
    }
    return Promise.reject(error);
  }
);

export default SaikyoApi;

