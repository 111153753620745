import React, { useContext, useState, useEffect, useCallback } from "react";
import { UserContext } from "../UserContext";
import SaikyoApi from "../SaikyoApi";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";

const columns = [
  {
    name: "Date",
    selector: (row) => row.completion_date,
  },
  {
    name: "Routine",
    selector: (row) => row.title,
  },
  {
    name: "Week",
    selector: (row) => row.week_no,
  },
  {
    name: "Day",
    selector: (row) => row.day_no,
  },
];

const WorkoutHistory = () => {
  const [workouts, setWorkouts] = useState([]);
  const navigate = useNavigate();
  const handleOnClick = useCallback(
    ($workout) => navigate("/workout/" + $workout.id, { replace: true }),
    [navigate]
  );

  useEffect(() => {
    const fetchData = async () => {
      const myWorkouts = await getWorkouts();
      setWorkouts(myWorkouts);
      return myWorkouts;
    };

    fetchData().catch(console.error);
  }, []);

  async function getWorkouts() {
    const myWorkouts = await SaikyoApi.get("/api/myWorkouts");
    return myWorkouts["data"];
  }

  return (
    <DataTable
      columns={columns}
      data={workouts}
      highlightOnHover
      pagination
      onRowClicked={handleOnClick}
    />
  );
};

export default WorkoutHistory;
