import React, { useContext, useState, useEffect, useCallback } from "react";
import { UserContext } from "../UserContext";
import SaikyoApi from "../SaikyoApi";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import UserProgramComponent from "../components/UserProgramComponent";

const columns = [
  {
    name: "Program",
    selector: (row) => row.title,
  },
  {
    name: "Week",
    selector: (row) => row.week_no,
  },
  {
    name: "Day",
    selector: (row) => row.day_no,
  },
  {
    name: "Date",
    selector: (row) => row.completion_date,
  },
];

const Programs = () => {
  const [programs, setPrograms] = useState([]);
  const navigate = useNavigate();
  const handleOnClick = useCallback(
    ($workout) => navigate("/workout/" + $workout.id, { replace: true }),
    [navigate]
  );

  useEffect(() => {
    const fetchData = async () => {
      const myPrograms = await getPrograms();
      setPrograms(myPrograms);
      return myPrograms;
    };
    fetchData().catch(console.error);
  }, []);

  async function getPrograms() {
    const myPrograms = await SaikyoApi.get("/api/routines");
    return myPrograms["data"];
  }

  return (
    <div>
      {programs.map((program) => {
        return <UserProgramComponent {...program} />;
      })}
    </div>
  );
};

export default Programs;
