import React, { useContext, useState, useEffect } from "react";
import SaikyoApi from "../SaikyoApi";
import UserActivityCalendar from "./UserActivityCalendar";

const MyStats = () => {
  const [userStats, setUserStats] = useState(null);

  useEffect(() => {
    console.log("MyStats");
    const fetchData = async () => {
      const _userStats = await getUserStats();
      setUserStats(_userStats);
      return _userStats;
    };

    fetchData().catch(console.error);
  }, []);

  async function getUserStats() {
    const userStats = await SaikyoApi.get("/api/userStats/1");
    return userStats["data"];
  }

  var setsPerWeek = 0;
  if (userStats != null) {
    for (var key of Object.keys(userStats.volumePerDateLastFourWeeks)) {
      setsPerWeek += userStats.volumePerDateLastFourWeeks[key] / 4;
    }
  }

  return (
    <div>
      <UserActivityCalendar {...userStats} />
      <table>
        <tbody>
          <tr>
            <th>Last 4 Weeks</th>
            <th></th>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Sets / Week</td>
            <td>{setsPerWeek}</td>
          </tr>
          <tr>
            <td>Chest</td>
            <td>
              {userStats != null
                ? userStats.volumePerMuscleGroupLastFourWeeks.Chest
                : 0}
            </td>
          </tr>
          <tr>
            <td>Back</td>
            <td>
              {userStats != null
                ? userStats.volumePerMuscleGroupLastFourWeeks.Back
                : 0}
            </td>
          </tr>
          <tr>
            <td>Shoulders</td>
            <td>
              {userStats != null
                ? userStats.volumePerMuscleGroupLastFourWeeks.Shoulders
                : 0}
            </td>
          </tr>
          <tr>
            <td>Arms</td>
            <td>
              {userStats != null
                ? userStats.volumePerMuscleGroupLastFourWeeks.Arms
                : 0}
            </td>
          </tr>
          <tr>
            <td>Quads</td>
            <td>
              {userStats != null
                ? userStats.volumePerMuscleGroupLastFourWeeks.Quads
                : 0}
            </td>
          </tr>
          <tr>
            <td>Hamstrings</td>
            <td>
              {userStats != null
                ? userStats.volumePerMuscleGroupLastFourWeeks.Hamstrings
                : 0}
            </td>
          </tr>
          <tr>
            <td>Calves</td>
            <td>
              {userStats != null
                ? userStats.volumePerMuscleGroupLastFourWeeks.Calves
                : 0}
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th>All-Time PRs</th>
            <th></th>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>Bench Press</td>
            <td>
              {userStats != null ? userStats.user.bench_pr_weight : 0}kg@
              {userStats != null ? userStats.user.bench_pr_reps : 0}
              Reps
            </td>
          </tr>
          <tr>
            <td>Squat</td>
            <td>
              {userStats != null ? userStats.user.squat_pr_weight : 0}kg@
              {userStats != null ? userStats.user.squat_pr_reps : 0}
              Reps
            </td>
          </tr>
          <tr>
            <td>Deadlift</td>
            <td>
              {userStats != null ? userStats.user.deadlift_pr_weight : 0}kg@
              {userStats != null ? userStats.user.deadlift_pr_reps : 0}
              Reps
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MyStats;
