import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import CalendarDayTile from "./CalendarDayTile";

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

const UserActivityCalendar = (props) => {
  return (
    <div>
      <Calendar
        showNavigation={false}
        tileContent={({ activeStartDate, date, view }) => (
          <CalendarDayTile
            date={formatDate(date)}
            radius={
              props != null &&
              "volumePerDateLastFourWeeks" in props &&
              formatDate(date) in props["volumePerDateLastFourWeeks"]
                ? props["volumePerDateLastFourWeeks"][formatDate(date)]
                : 1
            }
          />
        )}
        formatDay={() => ""}
      />
    </div>
  );
};

export default UserActivityCalendar;
