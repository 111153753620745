// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React, { useContext } from "react";
import { Navigate, Route, Outlet } from "react-router-dom";
import { UserContext } from "../UserContext";

const PrivateRoute = ({ element: Component, ...rest }) => {
  const isLoggedIn = localStorage.getItem("apiToken") != null;
  console.log(isLoggedIn);

  return isLoggedIn ? <Outlet /> : <Navigate to={{ pathname: "/login" }} />;
};

export default PrivateRoute;
