import React, { useEffect, useContext, useState } from "react";
import { MaleRanges } from "../util/StandardRanges";
import SaikyoApi from "../SaikyoApi";
import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import annotationPlugin from "chartjs-plugin-annotation";

ChartJS.register(
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

const ExerciseProgressChart = (props) => {
  const [progressionData, setProgressionData] = useState(null);
  const [progressionLineData, setProgressionLineData] = useState(null);
  const [usersRange, setUsersRange] = useState([5, 0]);
  const [isRelativeToBW, setRelativeToBW] = useState(props.isRelativeToBW);
  const [width, setWidth] = useState(900);
  const [height, setHeight] = useState(450);

  if (props.isRelativeToBW !== isRelativeToBW) {
    setRelativeToBW(props.isRelativeToBW);
  }

  const noobAnnotation = {
    type: "box",
    drawTime: "beforeDraw",
    backgroundColor: "rgba(204, 204, 204, 0.5)",
    borderColor: "rgb(204, 204, 204)",
    borderWidth: 1,
    yMax:
      props.exerciseId in MaleRanges
        ? MaleRanges[props.exerciseId]["Noob"][1]
        : 0,
    yMin:
      props.exerciseId in MaleRanges
        ? MaleRanges[props.exerciseId]["Noob"][0]
        : 0,
    yScaleID: "y",
    adjustScaleRange: false,
    label: {
      drawTime: "afterDraw",
      display: true,
      content: "Noob",
      position: {
        x: "start",
        y: "end",
      },
    },
  };

  const beginnerAnnotation = {
    type: "box",
    drawTime: "beforeDraw",
    backgroundColor: "rgba(137, 254, 126, 0.5)",
    borderColor: "rgb(137, 254, 126)",
    borderWidth: 1,
    yMax:
      props.exerciseId in MaleRanges
        ? MaleRanges[props.exerciseId]["Beginner"][1]
        : 0,
    yMin:
      props.exerciseId in MaleRanges
        ? MaleRanges[props.exerciseId]["Beginner"][0]
        : 0,
    // adjustScaleRange: false,
    yScaleID: "y",
    label: {
      drawTime: "afterDraw",
      display: true,
      content: "Begineer",
      position: {
        x: "start",
        y: "end",
      },
    },
  };

  const intermediateAnnotation = {
    type: "box",
    drawTime: "beforeDraw",
    backgroundColor: "rgba(171, 172, 254, 0.5)",
    borderColor: "rgb(171, 172, 254)",
    borderWidth: 1,
    yMax:
      props.exerciseId in MaleRanges
        ? MaleRanges[props.exerciseId]["Intermediate"][1]
        : 0,
    yMin:
      props.exerciseId in MaleRanges
        ? MaleRanges[props.exerciseId]["Intermediate"][0]
        : 0,
    // adjustScaleRange: false,
    yScaleID: "y",
    label: {
      drawTime: "afterDraw",
      display: true,
      content: "Intermediate",
      position: {
        x: "start",
        y: "end",
      },
    },
  };

  const advancedAnnotation = {
    type: "box",
    drawTime: "beforeDraw",
    backgroundColor: "rgba(250, 186, 87, 0.5)",
    borderColor: "rgb(250, 186, 87)",
    borderWidth: 1,
    yMax:
      props.exerciseId in MaleRanges
        ? MaleRanges[props.exerciseId]["Advanced"][1]
        : 0,
    yMin:
      props.exerciseId in MaleRanges
        ? MaleRanges[props.exerciseId]["Advanced"][0]
        : 0,
    // adjustScaleRange: false,
    yScaleID: "y",
    label: {
      drawTime: "afterDraw",
      display: true,
      content: "Advanced",
      position: {
        x: "start",
        y: "end",
      },
    },
  };

  const eliteAnnotation = {
    type: "box",
    drawTime: "beforeDraw",
    backgroundColor: "rgba(250, 186, 87, 0.5)",
    borderColor: "rgb(250, 186, 87)",
    borderWidth: 1,
    yMax:
      props.exerciseId in MaleRanges
        ? MaleRanges[props.exerciseId]["Elite"][1]
        : 0,
    yMin:
      props.exerciseId in MaleRanges
        ? MaleRanges[props.exerciseId]["Elite"][0]
        : 0,
    // adjustScaleRange: false,
    yScaleID: "y",
    label: {
      drawTime: "afterDraw",
      display: true,
      content: "Elite",
      position: {
        x: "start",
        y: "end",
      },
    },
  };

  const freakAnnotation = {
    type: "box",
    drawTime: "beforeDraw",
    backgroundColor: "rgba(250, 186, 87, 0.5)",
    borderColor: "rgb(250, 186, 87)",
    borderWidth: 1,
    yMax:
      props.exerciseId in MaleRanges
        ? MaleRanges[props.exerciseId]["Freak"][1]
        : 0,
    yMin:
      props.exerciseId in MaleRanges
        ? MaleRanges[props.exerciseId]["Freak"][0]
        : 0,
    // adjustScaleRange: false,
    yScaleID: "y",
    label: {
      drawTime: "afterDraw",
      display: true,
      content: "Freak",
      position: {
        x: "start",
        y: "end",
      },
    },
  };
  console.log(usersRange);

  const options = {
    responsive: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: props.chartTitle,
      },
      ...(isRelativeToBW && {
        annotation: {
          annotations: {
            // noobAnnotation,
            // beginnerAnnotation,
            // intermediateAnnotation,
            // advancedAnnotation,
            // eliteAnnotation,
            // freakAnnotation,
            ...(usersRange[0] - 1 <= 0 &&
              usersRange[1] + 1 >= 0 && { noobAnnotation }),
            ...(usersRange[0] - 1 <= 1 &&
              usersRange[1] + 1 >= 1 && { beginnerAnnotation }),
            ...(usersRange[0] - 1 <= 2 &&
              usersRange[1] + 1 >= 2 && { intermediateAnnotation }),
            ...(usersRange[0] - 1 <= 3 &&
              usersRange[1] + 1 >= 3 && { advancedAnnotation }),
            ...(usersRange[0] - 1 <= 4 &&
              usersRange[1] + 1 >= 4 && { eliteAnnotation }),
            ...(usersRange[0] - 1 <= 5 &&
              usersRange[1] + 1 >= 5 && { freakAnnotation }),
          },
        },
      }),
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "week",
        },
      },
    },
  };

  console.log(options);

  // if (nextProps.isRelativeToBW !== this.state.startTime) {
  //   this.setState({ startTime: nextProps.startTime });
  // }

  const createProgressionLineData = () => {
    let labels = progressionData.map((a) => a.completion_date);
    const progressionLineData = {
      labels,
      datasets: [
        {
          label: "Heaviest Weight",
          data: progressionData.map((a) =>
            isRelativeToBW ? a.best / a.bodyweight : a.best
          ),
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
        {
          label: "One-Rep Max (est.)",
          data: progressionData.map((a) =>
            isRelativeToBW ? a.onerm / a.bodyweight : a.onerm
          ),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };
    console.log(progressionLineData);
    setProgressionLineData(progressionLineData);
  };

  useEffect(() => {
    if (progressionData != null) createProgressionLineData();
  }, [progressionData, isRelativeToBW]);

  useEffect(() => {
    const fetchData = async () => {
      var minLevel = 5;
      var maxLevel = 0;
      const _progression = await getExerciseProgress();
      if (props.exerciseId in MaleRanges) {
        for (var workout of _progression) {
          var val = workout.onerm / workout.bodyWeight;
          const exerciseRange = MaleRanges[props.exerciseId];
          if (maxLevel < 1 && val >= exerciseRange["Beginner"][0]) maxLevel = 1;
          if (maxLevel < 2 && val >= exerciseRange["Intermediate"][0])
            maxLevel = 2;
          if (maxLevel < 3 && val >= exerciseRange["Advanced"][0]) maxLevel = 3;
          if (maxLevel < 4 && val >= exerciseRange["Elite"][0]) maxLevel = 4;
          if (maxLevel < 5 && val >= exerciseRange["Freak"][0]) maxLevel = 5;
          if (minLevel > 0 && val <= exerciseRange["Beginner"][0]) maxLevel = 0;
          if (minLevel > 1 && val <= exerciseRange["Intermediate"][0])
            minLevel = 1;
          if (minLevel > 2 && val <= exerciseRange["Advanced"][0]) minLevel = 2;
          if (minLevel > 3 && val <= exerciseRange["Elite"][0]) minLevel = 3;
          if (minLevel > 4 && val <= exerciseRange["Freak"][0]) minLevel = 4;
          if (val < usersRange[0] || usersRange[0] == 0) usersRange[0] = val;
          if (val > usersRange[1] || usersRange[1] == 0) usersRange[1] = val;
        }
        setUsersRange([minLevel, maxLevel]);
      }
      setProgressionData(_progression);
      return _progression;
    };

    fetchData().catch(console.error);
  }, []);

  async function getExerciseProgress() {
    const progression = await SaikyoApi.get(
      "/api/exerciseProgression/" + props.exerciseId
    );
    return progression["data"];
  }

  return (
    <div>
      {progressionLineData != null ? (
        <Line
          options={options}
          data={progressionLineData}
          width={width}
          height={height}
        />
      ) : (
        <div />
      )}
    </div>
  );
};

export default ExerciseProgressChart;
