export const Exercises = {
    "1": "Barbell Bench Press",
    "2": "Incline Dumbbell Bench Press",
    "3": "Bent Over Row",
    "4": "Lat Pull Down",
    "5": "Overhead Press",
    "6": "Barbell Curl",
    "7": "Skullcrusher",
    "8": "Squat",
    "9": "Deadlift",
    "10": "Leg Press",
    "11": "Leg Curl",
    "12": "Standing Machine Calf Raise",
    "13": "Leg Press Calf Raise",
    "14": "Incline Barbell Bench Press",
    "15": "Flat Bench Dumbbell Flye",
    "16": "Seated Cable Row",
    "17": "One Arm Dumbbell Row",
    "18": "Dumbbell Lateral Raise",
    "19": "Seated Incline Dumbbell Curl",
    "20": "Cable Tricep Extension",
    "21": "Front Squat",
    "22": "Barbell Lunge",
    "23": "Leg Extension",
    "24": "Seated Calf Raise",
    "25": "Machine Shoulder Press",
    "26": "Pec Deck Fly",
    "27": "Chest Dip",
    "28": "Dumbbell Bench Press",
    "29": "Pull Up",
    "30": "Chin Up",
    "31": "Machine Bench Press",
};