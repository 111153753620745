import { useEffect, useState } from "react";
import { UserContext } from "./UserContext";
import Home from "./pages/Home";
import {BrowserRouter as Router, Route, Routes, Link} from "react-router-dom"; 
import WorkoutHistory from "./pages/WorkoutHistory";
import WorkoutDetails from "./pages/WorkoutDetails";
import Programs from "./pages/Programs";
import Login from "./pages/Login";
import PrivateRoute from "./util/PrivateRoute";



function App(){
    const [userToken, setUserToken] = useState(null)
   
    useEffect(() => {
        // Runs only on the first render
        if(localStorage.getItem('apiToken') !== null){
            setUserToken(localStorage.getItem('apiToken'));
        }
    }, []);


    return (
        <Router>
            <nav>
                <ul>
                    <li>
                    <Link to="/">Home</Link>
                    </li>
                    <li>
                    <Link to="/workouts">My Workouts</Link>
                    </li>
                    <li>
                    <Link to="/programs">My Programs</Link>
                    </li>
                </ul>
            </nav>
            <UserContext.Provider value={{userToken, setUserToken}}>
                <Routes>
                    <Route path="/login" element={<Login/>}/>
                    {/*Protected routes*/}
                    <Route  path="/" element={<PrivateRoute/>}>
                        <Route  path="/" element={<Home/>}/>
                    </Route>
                    <Route  path="/" element={<PrivateRoute/>}>
                        <Route  path="/workouts" element={<WorkoutHistory/>}/>
                    </Route>
                    <Route  path="/" element={<PrivateRoute/>}>
                        <Route  path="/workout/:workout_id" element={<WorkoutDetails/>}/>
                    </Route>
                    <Route  path="/" element={<PrivateRoute/>}>
                        <Route  path="/programs" element={<Programs/>}/>
                    </Route>
                </Routes>
            </UserContext.Provider>
        </Router>
    );
}

export default App;