import React from "react";
import MyStats from "../components/MyStats";
import ExerciseProgressDashboard from "../components/ExerciseProgressDashboard";

const Home = () => {
  return (
    <div>
      <MyStats />
      <ExerciseProgressDashboard />
    </div>
  );
};

export default Home;
