import React from "react";
const UserProgramComponent = (props) => {
  return (
    <div>
      <b>{props.routine.title}</b>
      Week {props.current_week_no} Day {props.current_day_no} <br />
      {props.bench_pr_improvement - props.bench_pr_start > 0 && (
        <div>
          Bench Improvement {props.bench_pr_improvement - props.bench_pr_start}{" "}
          kg
          <br />
        </div>
      )}
      {props.squat_pr_improvement - props.squat_pr_start > 0 && (
        <div>
          Squat Improvement {props.squat_pr_improvement - props.squat_pr_start}{" "}
          kg
          <br />
        </div>
      )}
      {props.deadlift_pr_improvement - props.deadlift_pr_start > 0 && (
        <div>
          Deadlift Improvement
          {props.deadlift_pr_improvement - props.deadlift_pr_start} kg
          <br />
        </div>
      )}
    </div>
  );
};

export default UserProgramComponent;
