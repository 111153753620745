import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../UserContext";
import SaikyoApi from "../SaikyoApi";
import { useParams } from "react-router-dom";
import WorkoutExerciseComponent from "../components/WorkoutExerciseComponent";

const WorkoutDetails = (props) => {
  const [workout, setWorkout] = useState(null);
  const { workout_id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const myWorkout = await getWorkout(workout_id);
      setWorkout(myWorkout);
      return myWorkout;
    };
    fetchData().catch(console.error);
  }, []);

  async function getWorkout(id) {
    const myWorkouts = await SaikyoApi.get("/api/workout/" + id);
    return myWorkouts["data"];
  }

  console.log(workout);
  return (
    <div>
      {workout != null
        ? workout.exercises.map((exercise) => {
            return (
              <WorkoutExerciseComponent
                key={exercise.exercise_id}
                {...exercise}
              />
            );
          })
        : null}
    </div>
  );
};

export default WorkoutDetails;
