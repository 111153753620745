import React, { useContext, useState } from "react";
import { UserContext } from "../UserContext";

import ExerciseProgressChart from "./ExerciseProgressChart";
import { FormGroup, FormControlLabel, Switch } from "@mui/material";

const ExerciseProgressDashboard = () => {
  const [isRelativeToBW, setRelativeToBW] = useState(false);
  const handleRelativeToBWChange = () => {
    setRelativeToBW(!isRelativeToBW);
  };
  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={<Switch onChange={handleRelativeToBWChange} />}
          label="Relative to Bodyweight"
        />
      </FormGroup>
      <div>
        <ExerciseProgressChart
          exerciseId="1"
          chartTitle="Barbell Bench Press"
          isRelativeToBW={isRelativeToBW}
        />
      </div>
      <div>
        <ExerciseProgressChart
          exerciseId="8"
          chartTitle="Squat"
          isRelativeToBW={isRelativeToBW}
        />
      </div>
      <div>
        <ExerciseProgressChart
          exerciseId="9"
          chartTitle="Deadlift"
          isRelativeToBW={isRelativeToBW}
        />
      </div>
    </div>
  );
};

export default ExerciseProgressDashboard;
