import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { formatDate } from "react-calendar/dist/esm/shared/dateFormatter";

const CalendarDayTile = (props) => {
  const radius = Math.min(props.radius, 30);

  const dayStyle = {
    display: "flex",
    width: radius + "px",
    height: radius + "px",
    backgroundColor: "rgb(36,36,36)",
    borderRadius: "50%",
  };
  return <div style={dayStyle}></div>;
};
export default CalendarDayTile;
