import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../UserContext";
import {
  getAuth,
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
  FacebookAuthProvider,
} from "firebase/auth";
import SaikyoApi from "../SaikyoApi";
import { useNavigate } from "react-router-dom";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyApfAiZPNhEz8PBgph7LMOrnWeO4Wc2TCE",
  authDomain:
    window.location.hostname === "localhost"
      ? "saikyo-f7ca0.firebaseapp.com"
      : window.location.hostname,
  projectId: "saikyo-f7ca0",
  storageBucket: "saikyo-f7ca0.appspot.com",
  messagingSenderId: "389523236127",
  appId: "1:389523236127:web:19df599f135a1377e2bc2a",
  measurementId: "G-2RSHE588Y4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// const analytics = getAnalytics(app);
const provider = new FacebookAuthProvider();

const auth = getAuth();

const Login = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;
  const navigate = useNavigate();

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    getRedirectResult(auth)
      .then((result) => {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;

        const user = result.user;
        if (accessToken == null) {
          console.error("Access token is null");
        }
        getUser(accessToken);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);
        // ...
        console.error(errorMessage);
      });
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const setAuthorizationHeader = (token) => {
    if (token) {
      SaikyoApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete SaikyoApi.defaults.headers.common["Authorization"];
    }
  };

  async function getUser(token) {
    SaikyoApi.get("/sanctum/csrf-cookie").then((response) => {
      SaikyoApi.post("/api/login/facebook", {
        token: token,
      }).then((response) => {
        localStorage.setItem("apiToken", response["data"]["token"]);
        setAuthorizationHeader(response["data"]["token"]);
        navigate("/");
      });
    });
  }

  async function handleFacebookLogin() {
    if (isMobile) {
      signInWithRedirect(auth, provider);
      // then detect error with getRedirectResult
    } else {
      signInWithPopup(auth, provider)
        .then((result) => {
          // The signed-in user info.
          const user = result.user;

          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          const credential = FacebookAuthProvider.credentialFromResult(result);
          const accessToken = credential.accessToken;

          // ...
          console.log("Success");
          console.log(accessToken);
          getUser(accessToken);
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The AuthCredential type that was used.
          const credential = FacebookAuthProvider.credentialFromError(error);

          console.error(errorCode + " " + errorMessage);
          // ...
        });
    }
  }

  return (
    <button color="primary" onClick={handleFacebookLogin}>
      Sign in with Facebook
    </button>
  );
};

export default Login;
